const Loader = () => (
  <>
    <div className="w-100 text-center">
      <img
        src={require('assets/img/photo_swipe/preloader.gif').default}
        alt="ILPlatform Loader"
        height="auto"
      />
    </div>
  </>
);

export default Loader;
